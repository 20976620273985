<script lang="ts">
export default defineComponent({
    name: 'ArticleButton',
    props: {
        blok: {
            type: Object as PropType<ArticleButtonBlokInterface>,
            required: true,
        },
    },
});
</script>

<template>
    <div
        v-editable="blok"
        class="mb-100">
        <storyblok-link
            component="es-button"
            :link="blok.link"
            :variant="blok.variant"
            :inline="blok.inline"
            :outline="blok.outline"
            :size="blok.size"
            :disabled="blok.disabled"
            >{{ blok.text }}
            <icon-chevron-right
                v-if="blok.iconArrow"
                :class="String(blok.size) === 'sm' ? 'ml-50 condensed' : 'ml-25'"
        /></storyblok-link>
    </div>
</template>
<style scoped lang="scss">
.condensed {
    height: 1.125rem !important;
    width: 1.125rem !important;
}
</style>
